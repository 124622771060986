<template>
  <container-with-title
    title="e路播客户端优势"
    class="client-advantage"
    operate-text=""
  >
    <div class="list">
      <div class="item">
        <img src="@/assets/images/programme/稳定.png" />
        <div>
          <span>稳定网络</span>
          <p>提供急速、好用、安全、稳定、放心的专线出海通道。</p>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/安全.png" />
        <div>
          <span>安全合规</span>
          <p>
            依托国家数据跨境试点，拥有多年互联网安全经验，赋能大数据，完善的网络白名单机制，减少企业内部合规风险，全面尊重客户数据主权，有效保护直播企业出海业务安全。
          </p>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/全球.png" />
        <div>
          <span>全球覆盖</span>
          <p>
            覆盖全球的数据中心，提供伦敦、马尼拉、东京、胡志明、新加坡、曼谷、华盛顿、迪拜、法兰克福等地域线路供用户选择。
          </p>
        </div>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
export default {
  components: { ContainerWithTitle },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.client-advantage {
  width: 100%;
  background: none;

  .list {
    text-align: justify;
    .item {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 51px;
      @include flex(row, null, center);
      margin-bottom: 34px;
      padding: 101px 100px 68px 0;

      &:nth-child(1) {
        img {
          width: 266px;
          height: 288px;
          margin-left: 123px;
          margin-right: 92px;
        }
      }

      &:nth-child(2) {
        img {
          width: 435px;
          height: 249px;
          margin-left: 27px;
          margin-right: 19px;
        }
      }

      &:nth-child(3) {
        img {
          width: 285px;
          height: 323px;
          margin-left: 94px;
          margin-right: 102px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        object-fit: contain;
      }

      span {
        display: inline-block;
        font-size: 67px;
        font-weight: bold;
        color: #3661eb;
        margin-bottom: 44px;
      }
      p {
        font-size: 61px;
        color: #39425c;
        line-height: 92px;
        margin: 0;
      }
    }
  }
}
</style>
