<template>
  <container-with-title
    class="three-steps"
    title="出海直播三步走"
    operate-text=""
  >
    <div class="three-steps-container">
      <div class="step-imgs">
        <img class="step1" src="@/assets/images/programme/step1.png" />
        <i class="iconfont icon-jiantou_xiangyouliangci"></i>
        <img class="step2" src="@/assets/images/programme/step2.png" />
        <i class="iconfont icon-jiantou_xiangyouliangci"></i>
        <img class="step3" src="@/assets/images/programme/step3.png" />
      </div>

      <div class="steps">
        <div class="item">
          <img src="@/assets/images/programme/下载.png" />
          <span>①下载</span>
          <span>下载并安装客户端</span>
        </div>
        <div class="item">
          <img src="@/assets/images/programme/登录.png" />
          <span>②登录</span>
          <span>登录账号一键连接</span>
        </div>
        <div class="item">
          <img src="@/assets/images/programme/使用.png" />
          <span>③使用</span>
          <span>打开直播app，开启直播</span>
        </div>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
export default {
  components: { ContainerWithTitle },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

//@media screen and (max-width: 375px) {
//  .three-steps .steps span:last-of-type {
//    zoom: 0.8;
//  }
//}

.three-steps {
  width: 100%;
  height: 1417px;
  background: url("../../../../assets/images/programme/overseas-client-bg3.jpg")
    center no-repeat;
  background-size: cover;
  @include flex(column);
  border-radius: 0;

  .three-steps-container {
    flex: 1;
    @include flex(column, space-between);

    .step-imgs {
      //height: 562px;
      @include flex(row, space-between, center);

      .step1 {
        width: 277px;
      }

      .step2 {
        width: 458px;
      }

      .step3 {
        width: 529px;
      }

      .icon-jiantou_xiangyouliangci {
        font-size: 65px;
        color: #3661eb;
      }
    }

    .steps {
      height: 478px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10px;

      .item {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 10px 31px 3px rgba(207, 211, 219, 0.3);
        border-radius: 17px;
        @include flex(column, center, center);

        img {
          height: 140px;
          margin-bottom: 20px;
        }

        span {
          &:first-of-type {
            font-size: 67px;
            font-weight: bold;
            color: #3661eb;
            margin-bottom: 40px;
          }

          &:last-of-type {
            font-size: 51px;
            color: #39425c;
          }
        }
      }
    }
  }
}
</style>
