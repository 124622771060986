<template>
  <div class="live-across-the-sea">
    <container-with-title
      content-title="随时随地，享受快速稳定的跨海直播"
      operate-text=""
      :img-src="
        require('../../../assets/images/programme/overseas-client-bg1.png')
      "
      description="跨海直网络缓慢难以忍受？不用担心，我们目前在全球支持 80 个国家，170 个服务器位置，拥有超过 3000台服务器，并且还在持续地拓展服务。确保无论您从何处进行连接，都始终能在附近能找到高带宽服务器且低延迟的连接，享受最佳的性能。"
    >
      <div class="live-list">
        <div class="platforms">
          <div
            class="platform-item"
            v-for="item in SupportPlatform"
            :key="item.name"
          >
            <div class="img-container">
              <img :src="item.icon" />
            </div>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="ellipsis">
          <div class="ellipsis-item"></div>
          <div class="ellipsis-item"></div>
          <div class="ellipsis-item"></div>
        </div>
      </div>
    </container-with-title>
    <div class="tech-support">
      <div class="tech-support-list">
        <div class="item-wrap">
          <div class="item">
            <span>安全合规</span>
          </div>
        </div>
        <div class="item-wrap">
          <div class="item">
            <span>提供全球技术支持</span>
          </div>
        </div>
        <div class="item-wrap">
          <div class="item">
            <span>随时随地</span>
          </div>
        </div>
        <div class="item-wrap">
          <div class="item">
            <span>不限带宽 无限流量</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
import { SupportPlatform } from "../../../constant/system";
export default {
  components: { ContainerWithTitle },
  data() {
    return {
      SupportPlatform,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

//@media screen and (max-width: 375px) {
//  .live-list .platform-item > span {
//    zoom: 0.7;
//  }
//}

.live-across-the-sea {
  margin-top: 50px;
}

.live-list {
  display: flex;
  margin-top: 80px;
  margin-bottom: 68px;
  width: 100%;
  .platforms {
    display: flex;
    flex: 1;
  }
  .platform-item {
    position: relative;
    margin-right: auto;
    .img-container {
      width: 163px;
      height: 163px;
      background: #ffffff;
      box-shadow: 0px 10px 9px 1px rgba(0, 68, 142, 0.24);
      border-radius: 17px;
      overflow: hidden;
      margin-bottom: 36px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    span {
      font-size: 41px;
      font-weight: bold;
      color: #39425c;
      text-align: center;
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .ellipsis {
    width: 122px;
    height: 163px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .ellipsis-item {
      width: 22px;
      height: 22px;
      background: #c4c7d0;
      border-radius: 50%;
      margin-right: 29px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tech-support {
  width: 100%;
  height: 2219px;
  background: #3661eb;
  margin-top: 85px;
  .tech-support-list {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item-wrap {
      width: 50%;
      height: 50%;
      @include flex(row, center, center);
    }
    .item {
      width: 852px;
      height: 1012px;
      background: url("../../../../assets/images/programme/tech-support.png")
        center no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        color: #ffffff;
        display: block;
        letter-spacing: 2px;
        font-size: 82px;
        color: #ffffff;
      }
    }
  }
}
</style>
