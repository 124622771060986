<template>
  <div class="client">
    <div class="client-title">
      <div class="client-title-text">
        <p>全球直播</p>
        <p>GLOBAL LIVE BROADCAST</p>
        <p>领先一步，高性价比解决方案</p>
      </div>
    </div>

    <live-across-the-sea />

    <three-steps />

    <regional-package :connection-type="connectionType" />

    <advantage-comparison />

    <client-advantage />
  </div>
</template>

<script>
import LiveAcrossTheSea from "./client/LiveAcrossTheSea";
import ThreeSteps from "./client/ThreeSteps";
import RegionalPackage from "./RegionalPackage";
import AdvantageComparison from "./client/AdvantageComparison";
import ClientAdvantage from "./client/ClientAdvantage";
import { ConnectionTypeEnum } from "../../constant/system";

export default {
  components: {
    LiveAcrossTheSea,
    ThreeSteps,
    RegionalPackage,
    AdvantageComparison,
    ClientAdvantage,
  },
  data() {
    return {
      connectionType: ConnectionTypeEnum.Vpn,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.client {
  background: #d3e0ff;
  .client-title {
    height: 816px;
    background: url("../../../assets/images/programme/overseas-client.jpg")
      no-repeat;
    background-position: 0px 0px;
    background-size: 100% 100%;
    .client-title-text {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      @include flex(column, center);

      p {
        text-align: center;
        color: #ffffff;
        margin: 0;

        &:first-child {
          font-size: 92px;
          font-weight: bold;
        }
        &:nth-child(2) {
          font-size: 82px;
          font-weight: bold;
          margin-top: 60px;
          margin-bottom: 99px;
        }

        &:last-child {
          font-size: 48px;
        }
      }
    }
  }
}
</style>
